import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/EcoClub/2024/Recycling/1.jpg';
// import p2 from 'assests/Photos/Clubs/EcoClub/2024/Recycling/2.jpg';
// import p3 from 'assests/Photos/Clubs/EcoClub/2024/Recycling/3.jpg';
// import p4 from 'assests/Photos/Clubs/EcoClub/2024/Recycling/4.jpg';
// import p5 from 'assests/Photos/Clubs/EcoClub/2024/Recycling/5.jpg';
// import p6 from 'assests/Photos/Clubs/EcoClub/2024/Recycling/6.jpg';
// import p7 from 'assests/Photos/Clubs/EcoClub/2024/Recycling/7.jpg';
// import p8 from 'assests/Photos/Clubs/EcoClub/2024/Recycling/8.jpg';
// import p9 from 'assests/Photos/Clubs/EcoClub/2024/Recycling/9.jpg';





import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import EcoClubSidebar2024 from '../EnvironmentDay2023/EcoClubSidebar2024';


const Recycling2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/association/EcoClub/Recycling/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/association/EcoClub/Recycling/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/association/EcoClub/Recycling/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/association/EcoClub/Recycling/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/association/EcoClub/Recycling/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/association/EcoClub/Recycling/6.webp`;
  const p7 = `${config.base_image_url}/home/events-activities/association/EcoClub/Recycling/7.webp`;
  const p8 = `${config.base_image_url}/home/events-activities/association/EcoClub/Recycling/8.webp`;
  const p9 = `${config.base_image_url}/home/events-activities/association/EcoClub/Recycling/9.webp`;
  

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
        src: p7,
        source: p7,
        rows: 1,
        cols: 1,
      },
      {
          src: p8,
          source: p8,
          rows: 1,
          cols: 1,
        },
        {
          src: p9,
          source: p9,
          rows: 1.5,
          cols: 2,
        },

  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      RECYCLING OF PAPER
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 6  &  Date: 28 June 2024
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The Eco Club organised an activity ‘Reimagined Paper- From Waste to Art’- Recycling of Paper Activity for the students of Class 6 on Friday, 28 June 2024. The creativity, knowledge, and collaborative skills of students were demonstrated during the activity. They worked in teams to devise innovative ways of reusing paper to create artefacts. 
        <br></br>
        The activity challenged participants to delve into the complexities of paper recycling within the context of sustainability and changing global dynamics. Teams were tasked with proposing creative strategies to enhance the recycling and sustainable management of paper resources. 
        <br></br>
        The activity encouraged students to explore sustainable innovation and inspired them to think creatively in tackling real-world environmental challenges through paper recycling. It emphasised the significance of collective action and inter-disciplinary approaches to achieve a balance between utilising and conserving resources in our evolving world.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
      "Conserve Today, Preserve Tomorrow: Say No to Non-Renewables!" 

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <EcoClubSidebar2024/>
             </Box>
             {/* <Box marginBottom={4}>
               <Sidebar/>
             </Box> */}

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default Recycling2024;